import { createSlice } from "@reduxjs/toolkit";
import { AuthType } from "../typings/AuthType";
import { RootState } from "./store";

const initialState: AuthType = {
  uid: null,
  email: null,
  displayName: null,
  photoURL: null,
  authReady: false,
  admin: false,
  site: false,
  recent: 0,
  all: 0,
  premium: false,
  start: 0,
  end: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
      state.photoURL = action.payload.photoURL;
    },

    logout(state) {
      state.uid = null;
      state.email = null;
      state.displayName = null;
      state.photoURL = null;
      state.admin = false;
      state.site = false;
    },

    admin(state, action) {
      state.admin = action.payload;
    },

    premium(state, action) {
      state.premium = action.payload;
    },

    subscription(state, action) {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },

    site(state, action) {
      state.site = action.payload;
    },

    authReady(state, action) {
      state.authReady = action.payload;
    },

    count(state, action) {
      state.all = action.payload.all;
      state.recent = action.payload.recent;
    },
  },
});

export const {
  login,
  admin,
  premium,
  subscription,
  logout,
  authReady,
  site,
  count,
} = authSlice.actions;

export const authState = (state: RootState) => state.auth;

export default authSlice.reducer;
