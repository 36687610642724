import { useState } from "react";

import ImagePrompt from "../tools/ImagePrompt";

export default function Tools() {
  const [selectedTool, setSelectedTool] = useState<string>("Summarize Writing");

  const toolList = ["Generate Image"];
  window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-wrap items-center text-sm sm:text-base">
        {toolList.map((tool) => (
          <div
            key={tool}
            onClick={() => setSelectedTool(tool)}
            className={`px-2 sm:px-3 py-2 mb-2 mr-2 rounded-md cursor-pointer ${
              tool === selectedTool
                ? "bg-orange text-white"
                : "bg-orangeLight text-black"
            }`}
          >
            {tool}
          </div>
        ))}
      </div>

      <div>{selectedTool === "Generate Image" && <ImagePrompt />}</div>
    </div>
  );
}
