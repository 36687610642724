import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { label: "Tools", link: "/tools" },
    { label: "History", link: "/history" },
    { label: "Account", link: "/account" },
  ];

  const menuDisplay = menuItems.map((item, index) => (
    <div
      key={index}
      onClick={() => {
        setShowMenu(false);
        navigate(item.link);
      }}
      className={
        location.pathname === item.link ? "header-active" : "header-inactive"
      }
    >
      {item.label}
    </div>
  ));

  return (
    <div className="header-backdrop">
      <div className="header-content">
        <div>
          <div
            onClick={() => {
              setShowMenu(false);
              navigate("/");
            }}
            className={
              location.pathname === "/" ? "header-active" : "header-inactive"
            }
          >
            XREF.AI
          </div>
        </div>

        <div className="sm:hidden">
          <div
            className="header-inactive"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="material-symbols-rounded">menu</span>
          </div>
        </div>

        <div className="hidden sm:flex">{menuDisplay}</div>
      </div>
      {showMenu && <div className="sm:hidden">{menuDisplay}</div>}
    </div>
  );
}
