import { functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import { PromptType } from "../typings/PromptType";

const generateImageBinaryApi = httpsCallable(functions, "generateImageBinary");

export const generateImageBinary = async (data: PromptType) => {
  try {
    const response: any = await generateImageBinaryApi(data);
    return response.data;
  } catch (error: any) {
    console.log("ERROR", error);
    return { result: error.message, flagged: true };
  }
};
