import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, authState } from "../redux/authSlice";
import { auth } from "../firebase.config";

export default function Home() {
  const navigate = useNavigate();
  const state = useSelector(authState);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col items-center justify-center pt-12 pb-5 mx-auto my-auto space-y-10 border rounded-md px-7 sm:px-16 border-gray">
      <div className="flex flex-col text-center">
        <h1 className="px-10 py-5 mx-auto text-5xl text-white bg-orange w-fit">
          XREF.AI
        </h1>

        <h3 className="my-7">What would you like to create today?</h3>

        <br />
        {state.uid ? (
          <button
            className="btn btn-secondary"
            onClick={() => {
              signOut(auth);
              dispatch(logout());
            }}
          >
            Sign out
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => navigate("/login")}
          >
            Sign in (Free Trial!)
          </button>
        )}
      </div>
    </div>
  );
}
