import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const menuItems = [
    { label: "XREF.AI", link: "/" },
    { label: "Tools", link: "/tools" },
    { label: "History", link: "/history" },
    { label: "Account", link: "/account" },
    { label: "Pricing", link: "/pricing" },
    { label: "Privacy", link: "/privacy" },
    { label: "Terms", link: "/terms" },
  ];

  const menuDisplay = menuItems.map((item, index) => (
    <div
      key={index}
      onClick={() => navigate(item.link)}
      className="footer-link"
    >
      {item.label}
    </div>
  ));

  return (
    <div className="sticky bottom-0 flex flex-wrap items-center justify-center max-w-5xl px-5 pb-5 mx-auto space-x-2 z-1 text-gray">
      <div>&copy;2022</div>
      {menuDisplay}
    </div>
  );
}
