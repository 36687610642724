import { onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, functions } from "./firebase.config";
import { useDisable } from "./hooks/useDisable";
import Main from "./Main";
import {
  admin,
  authReady,
  authState,
  login,
  logout,
  site,
} from "./redux/authSlice";

export default function App() {
  useDisable();
  const [sitePassword, setSitePassword] = useState("");
  const state = useSelector(authState);
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isAuthorized = await httpsCallable(
      functions,
      "checkPassword"
    )(sitePassword);

    dispatch(site(isAuthorized));
    if (isAuthorized) {
      window.localStorage.setItem("sitePasswordPassed", "True");
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("sitePasswordPassed")) {
      dispatch(site(true));
    }
  }, [dispatch]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      dispatch(authReady(true));
      if (user) {
        dispatch(
          login({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          })
        );

        const response = await httpsCallable(functions, "checkAdmin")(user.uid);
        dispatch(admin(response.data));
      } else {
        dispatch(logout());
        window.localStorage.removeItem("sitePasswordPassed");
      }
    });
  }, [dispatch]);

  if (!state.authReady) return <p>Loading...</p>;
  if (state.site) return <Main />;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <form onSubmit={(e) => handleSubmit(e)}>
        <input
          className="px-4 py-2 border border-gray-300 rounded-md"
          type="password"
          placeholder="Enter Password"
          onChange={(e) => setSitePassword(e.target.value)}
        />
      </form>
    </div>
  );
}
