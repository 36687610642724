import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyB5nmLbjRx7zA7d9dMNP0gByhy6p3JwO74",
  authDomain: "mtpbuilder.firebaseapp.com",
  projectId: "mtpbuilder",
  storageBucket: "mtpbuilder.appspot.com",
  messagingSenderId: "678787879995",
  appId: "1:678787879995:web:c5e5ad86cd439b3c1ef81e",
  measurementId: "G-BW3LWWEJQD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { analytics, storage, db, auth, functions };
