import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.config";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid: string) {
  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await addDoc(
    collection(db, "customers", uid, "checkout_sessions"),
    {
      price: "price_1MF2NOJgkAs7TtIX4xFmJg8l",
      success_url: window.location.origin + "/account",
      cancel_url: window.location.origin + "/account",
    }
  );

  onSnapshot(
    checkoutSessionRef,
    async (snap) => {
      const sessionId = snap.data()?.sessionId;
      if (sessionId) {
        const stripe = await getStripe();
        stripe?.redirectToCheckout({ sessionId });
      }
    },
    (error) => {
      console.log("ERROR", error);
    }
  );
}
