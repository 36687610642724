import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "rk_test_51MF1WRJgkAs7TtIX2EdRrFTtpFiBmXbLPe75Ud9XqOM9ubYqYYB4SQ8Fv0VAbKvrVdGVM3Hm5lcm379kC9etd8Gf00Ke89w3Hv"
    );
  }
  return stripePromise;
};
export default initializeStripe;
