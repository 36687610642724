import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import { authState } from "./redux/authSlice";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Tools from "./pages/Tools";
import History from "./pages/History";
import Account from "./pages/Account";
import Login from "./pages/Login";
import FinishLogin from "./pages/FinishLogin";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

export default function Main() {
  const state = useSelector(authState);
  return (
    <BrowserRouter>
      <div className="screen">
        <Header />
        <div className="backdrop">
          <div className="page">
            <Routes>
              <Route
                path="/tools"
                element={state.uid ? <Tools /> : <Navigate to="/login" />}
              />
              <Route
                path="/history"
                element={state.uid ? <History /> : <Navigate to="/login" />}
              />
              <Route
                path="/account"
                element={state.uid ? <Account /> : <Navigate to="/login" />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/finish-login" element={<FinishLogin />} />
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}
