import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authState } from "../redux/authSlice";
import { createCheckoutSession } from "../stripe/createCheckoutSessions";
import { getPortalLink } from "../stripe/getPortalLink";

export default function Pricing() {
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex flex-col space-y-5">
      <h2 className="text-3xl">Pricing</h2>
      <main className="grid grid-cols-1 sm:grid-cols-2 gap-7">
        <section className="flex flex-col p-5 space-y-3 border rounded-md">
          <h3 className="text-xl">Free Trial</h3>
          <p>
            Try to XREF.AI for free to see just how much artificial intelligence
            can help you write faster and smarter!
          </p>
          <ul className="px-4 list-disc">
            <li>Up to 10 paragraphs for free</li>
            <li>Up to 4500 words generated for free</li>
            <li>That's up to 9 pages of single-spaced writing!</li>
          </ul>
        </section>
        <section className="flex flex-col p-5 space-y-3 border rounded-md">
          <h3 className="text-xl">Premium Plan</h3>
          <p>
            XREF.AI provides the most advanced artificial intelligence writing
            assistance with the most generous pricing plan.
          </p>
          <ul className="px-4 list-disc">
            <li>Up to 200 paragraphs every 30 days</li>
            <li>Up to 90,000 words generated every 30 days</li>
            <li>That's up to 180 pages of single-spaced writing!</li>
          </ul>
          <h3>Only $14.99 per month!</h3>
        </section>
      </main>
      <div>
        <h3 className="text-xl text-center mx-7">
          Write faster and smarter. Visualize ideas and create unique original
          images. Use AI to get so much more done in a day. Never have writer's
          block again!
        </h3>
      </div>

      {state.premium ? (
        <button
          className="btn btn-secondary"
          onClick={() => {
            toast.loading("Connecting Stripe...");
            getPortalLink();
          }}
        >
          View Subscription
        </button>
      ) : state.uid ? (
        <button
          className="btn btn-primary"
          onClick={() => {
            toast.loading("Connecting Stripe...");
            if (state.uid) createCheckoutSession(state.uid);
          }}
        >
          Upgrade
        </button>
      ) : (
        <button
          className="mx-auto btn btn-primary"
          onClick={() => navigate("/login")}
        >
          Try XREF.AI Today
        </button>
      )}
    </div>
  );
}
