import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase.config";
import ShowCount from "../components/ShowCount";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { v4 as uuidv4 } from "uuid";

import { generateImageBinary } from "../utils/generateImageBinary";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { toast } from "react-toastify";
import { PromptType } from "../typings/PromptType";

export default function ImagePrompt() {
  const state = useSelector(authState);

  const [prompt, setPrompt] = useState("");
  const [summary, setSummary] = useState<string>("");

  const [flagged, setFlagged] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);

  const [topic, setTopic] = useState<string>("");

  const [thinking, setThinking] = useState<boolean>(false);
  const monthlyMax = state.admin ? 500 : 200;
  const [localCount, setLocalCount] = useState<number>(state.all);

  useEffect(() => {
    const activeStatus =
      topic.length > 0 &&
      ((!state.premium && localCount < 10) ||
        (state.premium && localCount < monthlyMax));

    setActive(activeStatus);
  }, [localCount, monthlyMax, state.premium, topic.length]);

  async function saveHistory(
    prompt: string,
    response: string,
    topic: string,
    words: string,
    xrefs: string[]
  ) {
    if (state.uid) {
      const docRef = doc(collection(db, "users", state.uid, "summaries"));
      await setDoc(docRef, {
        id: docRef.id,
        prompt,
        response,
        topic,
        xrefs,
        words,
        timestamp: Timestamp.now(),
      }).then(async () => {
        // if (state.uid) {
        //   const { all, recent } = await checkCount(state.uid);
        //   dispatch(count({ all, recent }));
        // }

        setLocalCount(localCount + 1);
      });
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setActive(false);
    setSummary("");
    setFlagged("");
    setThinking(true);
    const id = toast.loading("Working on the design...");
    const prompt: PromptType = { freestyle: topic };
    const response = await generateImageBinary(prompt);

    setPrompt(response.prompt);
    setThinking(false);

    if (!response.flagged && state.uid) {
      const dataUrl = "data:image/png;base64," + response.result;
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      const file = new File([blob], "generated.png", { type: "image/png" });

      const filename = uuidv4();

      try {
        const storageRef = ref(
          storage,
          "generated/" + state.uid + "/" + filename + ".png"
        );
        const uploadTask = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(uploadTask.ref);
        setSummary(downloadURL);
        await saveHistory(response.prompt, downloadURL, topic, "image", []);
        toast.update(id, {
          render: "History saved...",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        setActive(true);
      } catch (error) {
        console.log("ERROR", error);
        toast.update(id, {
          render: "Error generating design...",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        setActive(true);
      }
    } else {
      setFlagged(
        "I can't do that. I can't do real people or anything the violates the terms of service. Please try changing the prompt."
      );
      toast.update(id, {
        render: "Issue with design...",
        type: "warning",
        isLoading: false,
        autoClose: 1000,
      });
      setActive(true);
    }
  };

  useEffect(() => {
    if (summary) {
      document
        .getElementById("response")
        ?.scrollIntoView({ behavior: "smooth" });
    } else if (prompt) {
      document.getElementById("prompt")?.scrollIntoView({ behavior: "smooth" });
    } else if (flagged) {
      document
        .getElementById("flagged")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [summary, prompt, flagged]);

  return (
    <div className="form-wrapper">
      <ShowCount title="Generate Image" />

      <form onSubmit={(e) => handleSubmit(e)}>
        <label htmlFor="topic-field">
          Prompt
          <textarea
            id="topic-field"
            rows={4}
            placeholder="Enter a freestyle prompt with any information or ideas you want to visualize"
            onChange={(e) => setTopic(e.target.value)}
          />
        </label>

        <button className="bottom" type="submit" disabled={!active}>
          Let's Visualize!
        </button>

        {!thinking && !Boolean(prompt) && (
          <>
            {localCount >= monthlyMax ? (
              <h3>{`You have reached your 30 day maximum of ${monthlyMax} prompts`}</h3>
            ) : (
              <h3>What would you like to visualize today?</h3>
            )}
          </>
        )}

        {thinking && !Boolean(summary) && !Boolean(flagged) && (
          <h3 id="prompt">I'm thinking ........</h3>
        )}

        {Boolean(flagged) && <h3 id="flagged">{flagged}</h3>}

        {!Boolean(flagged) && Boolean(summary) && (
          <div id="response">
            <h3 className="item">Here's what I came up with.</h3>
            <a href={summary} target="_blank" rel="noreferrer">
              <img
                src={summary}
                alt=""
                className="object-contain w-full py-3 cursor-pointer"
              />
            </a>

            <p className="disclaimer">
              <span>*</span>I'm a new AI and I'm still learning, so these
              results might have inaccuracies.
            </p>
          </div>
        )}
      </form>
    </div>
  );
}
