import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  Timestamp,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ShowCount from "../components/ShowCount";
import { count, authState } from "../redux/authSlice";
import { db } from "../firebase.config";

import { UserHistoryType } from "../typings/HistoryType";
import { checkCount } from "../utils/checkCount";

export default function History() {
  const dispatch = useDispatch();
  const state = useSelector(authState);

  const [summaries, setSummaries] = useState<UserHistoryType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [lastKey, setLastKey] = useState<Timestamp | undefined>(undefined);

  const orderedSummaries = summaries
    .slice()
    .sort((a, b) =>
      b.timestamp.seconds > a.timestamp.seconds
        ? 1
        : b.timestamp.seconds < a.timestamp.seconds
        ? -1
        : 0
    );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const getData = async () => {
      if (state.uid) {
        const c = collection(db, "users", state.uid, "summaries");
        const q = query(c, orderBy("timestamp", "desc"), limit(100));
        const querySnapshot = await getDocs(q);

        const s: UserHistoryType[] = [];
        let lastKey: Timestamp | undefined = undefined;
        querySnapshot.forEach((doc) => {
          const d = doc.data();

          s.push({
            id: d.id,
            prompt: d.prompt,
            response: d.response,
            timestamp: d.timestamp,
            topic: d.topic,
            words: d.words,
            xrefs: d.xrefs,
          });
          lastKey = doc.data().timestamp;
        });

        setSummaries(s);
        setLastKey(lastKey);

        checkCount(state.uid).then(({ all, recent }) => {
          if (all !== state.all || recent !== state.recent) {
            // dispatch({ type: "COUNT", payload: { all, recent } });
            dispatch(count({ all, recent }));
          }
        });
      }
    };
    getData();
  }, [dispatch, state.all, state.recent, state.uid]);

  const postsNextBatch = async (key: Timestamp) => {
    if (state.uid) {
      const id = toast.loading("Loading history...");
      const c = collection(db, "users", state.uid, "summaries");
      const q = query(
        c,
        orderBy("timestamp", "desc"),
        startAfter(key),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      const s: UserHistoryType[] = summaries;
      let lastKey: Timestamp | undefined;
      querySnapshot.forEach((doc) => {
        const d = doc.data();

        s.push({
          id: d.id,
          prompt: d.prompt,
          response: d.response,
          timestamp: d.timestamp,
          topic: d.topic,
          words: d.words,
          xrefs: d.xrefs,
        });
        lastKey = doc.data().timestamp;
      });
      toast.update(id, {
        render: "History loaded...",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      setSummaries(s);
      setLastKey(lastKey);
    }
  };

  if (!state.uid) return <div>Not signed in</div>;

  return (
    <div className="flex flex-col space-y-5">
      <ShowCount title="History" />

      <input
        className="px-3 py-2 border rounded-md outline-none"
        type="text"
        placeholder="Filter results..."
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="flex flex-col space-y-5">
        {orderedSummaries &&
          orderedSummaries
            .filter((summary) =>
              (summary.response + " " + summary.prompt)
                .toUpperCase()
                .includes(search ? search.toUpperCase() : "")
            )
            .map((summary, index) => (
              <div key={index} className="p-3 rounded-md shadow-md">
                <div>
                  {new Date(summary.timestamp.seconds * 1000).toLocaleString()}
                </div>

                <div className="">{summary.prompt}</div>
                {summary.words === "image" ? (
                  <div>
                    <a href={summary.response} target="_blank" rel="noreferrer">
                      <img
                        src={summary.response}
                        alt=""
                        className="displayImage"
                      />
                    </a>
                  </div>
                ) : (
                  <div className="response">{summary.response}</div>
                )}
              </div>
            ))}
      </div>
      {lastKey && (
        <button onClick={() => postsNextBatch(lastKey)}>Load More</button>
      )}
    </div>
  );
}
