import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { authState, count } from "../redux/authSlice";
import { createCheckoutSession } from "../stripe/createCheckoutSessions";
import { checkCount } from "../utils/checkCount";

type ShowCountProps = {
  title: string;
};
export default function ShowCount({ title }: ShowCountProps) {
  const state = useSelector(authState);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getCount() {
      if (state.uid) {
        checkCount(state.uid).then(({ all, recent }) => {
          dispatch(count({ all, recent }));
        });
      }
    }
    getCount();
  }, [dispatch, state.uid]);

  return (
    <div className="flex items-center justify-between">
      <h1 className="text-2xl">{title}</h1>
      {state.premium ? (
        <div className="flex items-center space-x-2">
          <div className="flex px-3 py-2 space-x-1 text-white rounded-md bg-blue">
            <span>{state.all}</span>
            <span>Prompts</span>
          </div>
          <span className="px-3 py-2 text-white rounded-md bg-orange">
            Premium
          </span>
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <div className="flex px-3 py-2 space-x-1 text-white rounded-md bg-blue">
            <span className="counter">{Math.max(0, 10 - state.all)}</span>
            <span>Remaining</span>
          </div>
          <span
            className="px-3 py-2 text-white rounded-md cursor-pointer bg-orange"
            onClick={async () => {
              toast.loading("Connecting Stripe...");
              if (state.uid) await createCheckoutSession(state.uid);
            }}
          >
            Upgrade
          </span>
        </div>
      )}
    </div>
  );
}
